import * as React from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  FormField,
  Header,
  Icon,
  Input,
  RadioGroup,
  SpaceBetween
} from '@amzn/awsui-components-react-v3';
import { ItemDetailModel } from "../../models/item-detail-model";
import { QuestionModel, Question_Status } from "../../models/question-model";
import { UIModel } from "../../models/ui-model";

export const REQUIRED_MESSAGE = 'Required';

export namespace ItemQuestionnaire {
  export const SingleSelectQuesiton = (props: {
    questionItem: {
      questionTextLabelled: ItemDetailModel.UI.Container.QuestionTextLabelled,
      question: QuestionModel.Data,
      answer: string | string[],
      status: Question_Status
    },
    operation: {
      onEditQuestion: () => void,
      onAnswerQuestion: (answer: string) => void,
      onRefreshQuestion: () => void,
      onCancel: () => void,
    }
  }) => {
    const options = props.questionItem.question.Options.map(
      (option, index) => {
        return {
          value: option.Criteria.Value,
          label: props.questionItem.questionTextLabelled.Options.Criteria[index].Value
        }
      });

      const initialValue = props.questionItem.answer as string
      const editSelectedAnswer = UIModel.FieldState.use({ initialValue: initialValue });
    const editView = (
      <FormField
        id={props.questionItem.question.Id} label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
        description={props.questionItem.question.Description}>
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant='link' onClick={() => {
                props.operation.onCancel()
              }}>Cancel</Button>
              <Button id="update" onClick={
                () => {
                  props.operation.onAnswerQuestion(editSelectedAnswer.value)
                }}
                variant="primary"
                iconName="status-positive"
                className='awsui-util-pr-l'
              >
                Update
              </Button>
            </SpaceBetween>
          }
        >
          <RadioGroup
            value={editSelectedAnswer.value}
            className='awsui-util-pl-xxl awsui-util-pb-l'
            items={options}
            onChange={(event) => editSelectedAnswer.setValue(event.detail.value)}
          />
        </Header>
      </FormField>
    )

    const expandView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
        description={props.questionItem.question.Description}>
        <RadioGroup
          value={initialValue}
          className='awsui-util-pl-xxl awsui-util-pb-l'
          items={options}
          onChange={(event) => {
            props.operation.onAnswerQuestion(event.detail.value)
          }}
        />
      </FormField>
    );

    const closedView = (
      <FormField id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        description={props.questionItem.question.Description}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{props.questionItem.answer}</strong>
          <Button id="edit" onClick={() => {
              props.operation.onEditQuestion()
            }} variant="link" iconName="edit" className='awsui-util-pr-l'>Edit
          </Button>
        </div>
      </FormField>
    )

    const readOnlyView = (
      <FormField id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        description={props.questionItem.question.Description}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{props.questionItem.answer}</strong>
        </div>
      </FormField>
    )
    const archivedView = (
      <FormField id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        description={<p><Icon variant="warning" name="status-warning"></Icon>This question has been updated, click refresh to answer the latest version</p>}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{props.questionItem.answer}</strong>
          <Button id="edit" onClick={() => {
            props.operation.onRefreshQuestion()
          }} variant="primary" iconName="refresh" className='awsui-util-pr-l'>Refresh</Button>
        </div>
      </FormField>
    );

    return (
      <>
        {props.questionItem.status === Question_Status.ARCHIVED && archivedView}
        {props.questionItem.status === Question_Status.EDIT_FOCUS && editView}
        {props.questionItem.status === Question_Status.READ_ONLY && readOnlyView}
        {props.questionItem.status === Question_Status.EXPANDED && expandView}
        {props.questionItem.status === Question_Status.CLOSED && closedView}
      </>
    )
  }

  export const MultiSelectQuesiton = (props: {
    questionItem: {
      questionTextLabelled: ItemDetailModel.UI.Container.QuestionTextLabelled,
      question: QuestionModel.Data,
      answer: string | string[],
      status: Question_Status
    },
    operation: {
      onEditQuestion: () => void,
      onAnswerQuestion: (answer: string[]) => void,
      onRefreshQuestion: () => void,
      onCancel: () => void,
    }
  }) => {
    const NONE_OF_THE_ABOVE_VALUE = "none of the above";
    const selectedValue = UIModel.FieldState.use<string[]>({ initialValue: props.questionItem.answer ? props.questionItem.answer as string[] : [] });
    const handleChange = (eventDetail: CheckboxProps.ChangeDetail, option: QuestionModel.AnswerOption, selectedValue: UIModel.FieldState<string[]>) => {
      if (eventDetail.checked) {
        if (option.Criteria.Value.toLowerCase().includes(NONE_OF_THE_ABOVE_VALUE)) {
          selectedValue.setValue([option.Criteria.Value])
        } else {
          selectedValue.setValue([
            ...selectedValue.value.filter((o) => !o.toLowerCase().includes(NONE_OF_THE_ABOVE_VALUE)),
            option.Criteria.Value
          ]);
        }
      } else {
        selectedValue.setValue([...selectedValue.value.filter(v => v !== option.Criteria.Value)]);
      }
    }
    const options = props.questionItem.question.Options.map((option) => {
      return <Checkbox
        onChange={(event) => {
          handleChange(event.detail, option, selectedValue)
        }}
        checked={selectedValue.value.findIndex(v => v === option.Criteria.Value) !== -1}
      >{option.Criteria.Value}
      </Checkbox>
    });
    const editView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant='link' onClick={() => {
                props.operation.onCancel()
              }}>Cancel</Button>
              <Button id="updateAnswer" onClick={() => {
                props.operation.onAnswerQuestion(selectedValue.value);
              }} variant="primary" className='awsui-util-pr-l'>Update</Button>
            </SpaceBetween>
          }
        >
          {options}
        </Header>
      </FormField>
    )

    const expandView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
                  <Header
          actions={
            <Button id="updateAnswer" onClick={() => {
              props.operation.onAnswerQuestion(selectedValue.value);
            }} variant="primary" className='awsui-util-pr-l'>Update</Button>
          }
        >
          {options}
        </Header>
      </FormField>
    );

    const closedView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{selectedValue.value.map((answer) => {
              return <div>{answer}</div>
            })}
          </strong>
          <SpaceBetween direction="horizontal" size="xs">
            <Button id="edit" onClick={() => {
              props.operation.onEditQuestion()
            }}
              variant="link"
              iconName="edit"
            >Edit</Button>
          </SpaceBetween>
        </div>
      </FormField>
    );


    const readOnlyView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{selectedValue.value.map((answer) => {
              return <div>{answer}</div>
            })}
          </strong>
        </div>
      </FormField>
    );

    const archivedView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{selectedValue.value.map((answer) => {
              return <div>{answer}</div>
            })}
          </strong>
          <Button id="edit" onClick={() => {
              props.operation.onRefreshQuestion()
            }}
              variant="primary"
              iconName="refresh"
            >Refresh
          </Button>
        </div>
      </FormField>
    );

    return (
      <>
        {props.questionItem.status === Question_Status.ARCHIVED && archivedView}
        {props.questionItem.status === Question_Status.EDIT_FOCUS && editView}
        {props.questionItem.status === Question_Status.READ_ONLY && readOnlyView}
        {props.questionItem.status === Question_Status.EXPANDED && expandView}
        {props.questionItem.status === Question_Status.CLOSED && closedView}
      </>
    );
  }

  export const TextQuesiton = (props: {
    questionItem: {
      questionTextLabelled: ItemDetailModel.UI.Container.QuestionTextLabelled,
      question: QuestionModel.Data,
      answer: string | string[],
      status: Question_Status
    },
    operation: {
      onEditQuestion: () => void,
      onAnswerQuestion: (answer: string) => void,
      onRefreshQuestion: () => void,
      onCancel: () => void,
    }
  }) => {
    const editSelectedAnswer = UIModel.FieldState.use({ initialValue: props.questionItem.answer as string });

    const editView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <Input
          value={editSelectedAnswer.value}
          onChange={(event) => {
            editSelectedAnswer.setValue(event.detail.value);
          }}
        />
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant='link' onClick={() => {
                props.operation.onCancel()
              }}>Cancel</Button>
              <Button id="update" onClick={() => {
                props.operation.onAnswerQuestion(editSelectedAnswer.value)
              }} variant="primary" iconName="status-positive" className='awsui-util-pr-l'>Update</Button>
            </SpaceBetween>
          }
        >
        </Header>
      </FormField>
    )

    const expandView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <Input
          value={editSelectedAnswer.value}
          onChange={(event) => {
            editSelectedAnswer.setValue(event.detail.value);
          }}
        />
        <Header
          actions={
            <Button id="confirm" onClick={() => {
              props.operation.onAnswerQuestion(editSelectedAnswer.value)
            }} variant="primary" iconName="status-positive" className='awsui-util-pr-l'>Confirm</Button>
          }
        >
        </Header>
      </FormField>
    );

    const closedView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{props.questionItem.answer}</strong>
          <Button id="edit" onClick={() => {
              props.operation.onEditQuestion()
            }} variant="link" iconName="edit" className='awsui-util-pr-l'>Edit
          </Button>
        </div>
      </FormField>
    );


    const readOnlyView =
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{props.questionItem.answer}</strong>
        </div>
      </FormField>;

    const archivedView = (
      <FormField
        id={props.questionItem.question.Id}
        label={<div className='question'><span>{props.questionItem.questionTextLabelled.DisplayText}</span></div>}
        description={<p><Icon variant="warning" name="status-warning"></Icon>This question has been updated, click refresh to answer the latest version</p>}
        stretch={true}
      >
        <div className="left-right-spacing">
          <strong>{props.questionItem.answer}</strong>
          <Button id="edit" onClick={() => {
              props.operation.onRefreshQuestion()
            }} variant="primary" iconName="refresh" className='awsui-util-pr-l'>Refresh
          </Button>
        </div>
      </FormField>
    );

    return (
      <>
        {props.questionItem.status === Question_Status.ARCHIVED && archivedView}
        {props.questionItem.status === Question_Status.EDIT_FOCUS && editView}
        {props.questionItem.status === Question_Status.READ_ONLY && readOnlyView}
        {props.questionItem.status === Question_Status.EXPANDED && expandView}
        {props.questionItem.status === Question_Status.CLOSED && closedView}
      </>
    )
  }
}