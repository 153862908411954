export const militaryUseOptions = [
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  },
]

export const researchProjectOptions = [
  {
    value: 'Yes',
    label: 'Yes',
    description: 'This Item is part of a basic or applied research project'
  },
  {
    value: 'No',
    label: 'No',
    description: 'This Item is not part of a basic or applied research project'
  },
]

export const cetNotApplicableOption = "Not Applicable"

export const cetMultiOptions = [
  { 
    value: "Advanced Computing",
    description: "Super computing, Edge computing, Cloud computing, High-performance data storage and data centers, Computing architectures, Data processing and analysis techniques, Advanced modeling and simulation, Spatial computing"
  },
  {
    value: "Artificial Intelligence",
    description: "Machine learning, Deep learning, Reinforcement learning, Sensory perception and recognition, Next-generation AI, Planning, reasoning, and decision making, Safe and/or secure AI, AI assurance and assessment techniques, Foundation models, Generative AI systems, multimodal, and large language models (LLM), Synthetic data approaches for training, tuning, and testing"
  },
  {
    value: "Integrated Communication and Networking Technologies",
    description: "Radio-frequency (RF) and mixed-signal circuits, antennas, filters, and components, Spectrum management technologies, Next-generation wireless networks, including 5G and 6G, Optical links and fiber technologies, Terrestrial/undersea cables, Satellite-based communications, Hardware, firmware, and software, Communications and network security, Mesh networks/infrastructure independent communication technologies, Software-defined networking and radios, Modern data exchange techniques, Adaptive network controls, Resilient and adaptive waveforms"
  },
  {
    value: "Data Privacy, Data Security, and Cybersecurity Technologies",
    description: "Distributed ledger technologies, Digital assets, Digital payment technologies, Digital identity technologies, biometrics, and associated infrastructure, Communications and network security, Privacy-enhancing technologies, Technologies for data fusion and improving data interoperability, privacy, and security, Distributed confidential computing, Computing supply chain security, Security and privacy technologies in augmented reality/virtual reality"
  },
  {
    value: "Semiconductors and Microelectronics",
    description: "Design and electronic design automation tools, Manufacturing process technologies and manufacturing equipment, Beyond complementary metal-oxide-semiconductor (CMOS) technology, Heterogeneous integration and advanced packaging, Specialized/tailored hardware components for artificial intelligence, natural and hostile radiation environments, RF and optical components, high-power devices, and other critical applications, Novel materials for advanced microelectronics, Microelectromechanical systems (MEMS) and Nanoelectromechanical systems (NEMS), Novel architectures for non-Von Neumann computing"
  },
  {
    value: "Quantum Information Technologies", 
    description: "Quantum computing, Materials, isotopes, and fabrication techniques for quantum devices, Post-quantum cryptography, Quantum sensing, Quantum communications and networking, Supporting systems "
  },
  {
    value: "Other Emerging Technologies",
    description: "Advanced Engineering Materials, Advanced Gas Turbine Engine Technologies, Advanced Manufacturing, Advanced and Networked Sensing and Signature Management, Advanced Nuclear Energy Technologies, Highly automated, autonomous, and uncrewed systems (UxS), and Robotics, Biotechnologies, Directed Energy, Human-Machine Interfaces, Hypersonics, Networked Sensors and Sensing, Clean energy generation and storage, Space Technologies and Systems, Positioning, Navigation, and Timing (PNT) Technologies"
  },
  {
    value: cetNotApplicableOption
  }
]

export const highlyConfidentialOptions = [
  { label: "Yes", value: "Yes", description: "This item is Highly Confidential" },
  { label: "No", value: "No", description: "This item is not Highly Confidential" }
];

// Values should not be changed
export const ECNItemTypeDescriptor = [
  { label: "Access Point", value: "ECNItemType-1" },
  { label: "Cellular", value: "ECNItemType-2" },
  { label: "Computer or Computing Platforms", value: "ECNItemType-3" },
  { label: "Computer Forensics", value: "ECNItemType-4" },
  { label: "Cryptographic Accelerator", value: "ECNItemType-5" },
  { label: "Data Backup and Recovery", value: "ECNItemType-6" },
  { label: "Database", value: "ECNItemType-7" },
  { label: "Disk/Drive Encryption", value: "ECNItemType-8" },
  { label: "Distributed Computing", value: "ECNItemType-9" },
  { label: "Email Communications", value: "ECNItemType-10" },
  { label: "Fax Communications", value: "ECNItemType-11" },
  { label: "File Encryption", value: "ECNItemType-12" },
  { label: "Firewall", value: "ECNItemType-13" },
  { label: "Gateway", value: "ECNItemType-14" },
  { label: "Intrusion Detection", value: "ECNItemType-15" },
  { label: "Identity Management", value: "ECNItemType-16" },
  { label: "Key Exchange", value: "ECNItemType-17" },
  { label: "Key Management", value: "ECNItemType-18" },
  { label: "Key Storage", value: "ECNItemType-19" },
  { label: "Link Encryption", value: "ECNItemType-20" },
  { label: "Local Area Networking (LAN)", value: "ECNItemType-21" },
  { label: "Metropolitan Area Networking (MAN)", value: "ECNItemType-22" },
  { label: "Mobility and Mobile Applications N.E.S.", value: "ECNItemType-23" },
  { label: "Modem", value: "ECNItemType-24" },
  { label: "Multimedia N.E.S.", value: "ECNItemType-25" },
  { label: "Network Convergence or Infrastructure N.E.S.", value: "ECNItemType-26" },
  { label: "Network Forensics", value: "ECNItemType-27" },
  { label: "Network Intelligence", value: "ECNItemType-28" },
  { label: "Network or Systems Management (OAM/OAM&P)", value: "ECNItemType-29" },
  { label: "Network Security Monitoring", value: "ECNItemType-30" },
  { label: "Network Vulnerability and Penetration Testing", value: "ECNItemType-31" },
  { label: "Operating System", value: "ECNItemType-32" },
  { label: "Optical Networking", value: "ECNItemType-33" },
  { label: "Radio Communications", value: "ECNItemType-34" },
  { label: "Router", value: "ECNItemType-35" },
  { label: "Satellite Communications", value: "ECNItemType-36" },
  { label: "Short Range Wireless N.E.S.", value: "ECNItemType-37" },
  { label: "Storage Area Networking (SAN)", value: "ECNItemType-38" },
  { label: "3G/4G/5G/LTE/WiMAX", value: "ECNItemType-39" },
  { label: "Trusted Computing", value: "ECNItemType-40" },
  { label: "Videoconferencing", value: "ECNItemType-41" },
  { label: "Virtual Private Networking (VPN)", value: "ECNItemType-42" },
  { label: "Voice Communications N.E.S.", value: "ECNItemType-43" },
  { label: "Voice over Internet Protocol (VoIP)", value: "ECNItemType-44" },
  { label: "Wide Area Networking (WAN)", value: "ECNItemType-45" },
  { label: "Wireless Local Area Networking (WLAN)", value: "ECNItemType-46" },
  { label: "Wireless Personal Area Networking (WPAN)", value: "ECNItemType-47" },
  { label: "Test Equipment N.E.S.", value: "ECNItemType-48" },
  { label: "Other (Please Specify)", value: "ECNItemType-49" },
];