import * as React from "react";
import {
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  DatePicker,
  FormField,
  Header,
  Icon,
  Input,
  Link,
  Multiselect,
  SpaceBetween,
  RadioGroup,
  Spinner,
  Textarea,
  Table,
  Toggle,
  Select
} from '@amzn/awsui-components-react-v3';
import { getEmployeeSummary, updateECCNLock } from "../../actions/async.action";
import { ItemDetailModel, ECCN_STATUS, REVIEW_STATUS, CCATsQuestions } from "../../models/item-detail-model";
import { Item } from "../../models/item-model";
import { formatStringToDate } from "../../utils/sharedFunctions";
import AdditionalContacts from "../shared/AdditionalContacts";
import RepeaterTable from "../shared/RepeaterTable";
import { Label } from "../shared/Label";
import { EmployeeSuggestions } from "../shared/EmployeeSuggestions";
import { TextLink } from "../shared/TextLink";
import FileAttachments from "../shared/FileAttachments"
import { HelpPaneContext } from "../shared/HelpPane/HelpPane";
import { Pane } from "../shared/HelpPane/HelpPane.constants";
import { Type } from "../shared/HelpPane/questionnairePane/QuestionnairePane.constants";
import { ItemQuestionnaire } from "./ItemQuestionnaire"
import { ItemLink } from "../shared/ItemLink"
import { ItemMultiSelect } from "../shared/ItemMultiSelect";
import { EmployeeLabel } from "../shared/EmployeeLabel";
import { TeamLink } from "../shared/TeamLink";
import { cetMultiOptions, militaryUseOptions, researchProjectOptions, highlyConfidentialOptions, cetNotApplicableOption, ECNItemTypeDescriptor } from "../Itemv2/questionOption.constants";
import { Builder } from "builder-pattern";
import { QuestionModel } from "../../models/question-model";
import { store } from "../../main";

export const REQUIRED_MESSAGE = 'Required';

const Confidential = (props: { confidential: string }) => {
  return (<div>
    <div className="awsui-util-label">Highly confidential</div>
    <div>{props.confidential}</div>
  </div>)
}

const ECCNLabel = (props: { 
    ECCNStatus: ECCN_STATUS, 
    ECCN: string, 
    ECCNLocked: boolean, 
    ECCNLockDisabled: boolean,
    onChange? : (detail) => void
  }) => {
  const active = props.ECCNStatus === ECCN_STATUS.ACTIVE
  return (
    <>
      <Box variant="awsui-key-label">ECCN</Box>
      <SpaceBetween direction="vertical" size="m">
        <SpaceBetween direction="horizontal" size="m">
          {active && <div style={{ color: 'green' }}><Icon name="status-positive" variant='success' className='awsui-util-pr-s' />{props.ECCN}</div>}
          {!active && <div><Icon name="status-pending" className='awsui-util-pr-s' />{props.ECCN}</div>}
          <Toggle
              checked={props.ECCNLocked}
              onChange={props.onChange}
              disabled={props.ECCNLockDisabled}
          >
            {props.ECCNLocked ? "Locked" : "Not Locked"}
          </Toggle>
        </SpaceBetween>

        <Label name='ECCN Status' info={props.ECCNStatus} />
      </SpaceBetween>
    </>
  )
}

export namespace ItemContainer {
  export const ItemSummary = (props: { state: ItemDetailModel.UI.Container.SummaryState }) => {
    const defaultView = (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => props.state.onEdit()}>Edit</Button>
              </SpaceBetween>
            }
          >
            Summary
          </Header>
        }
      >
        <ColumnLayout columns={3} borders="vertical">
          {<Label name="Item full name" info={props.state.itemFullName.value} />}
          {<Label name="Internal code name" info={props.state.internalCodeName.value} />}
          {<Label name="Item version" info={props.state.customerDefinedVersion.value} />}
          {<Confidential confidential={props.state.highlyConfidential.value} />}
          {<Label name="Item type" info={props.state.itemType.value} />}
          { props.state.initialItemState.value.DualUse === "Yes" &&
            <ECCNLabel 
              ECCNStatus={props.state.initialItemState.value.ECCNStatus} 
              ECCN={props.state.initialItemState.value.ECCN} 
              ECCNLocked={props.state.initialItemState.value.ECCNLocked}
              ECCNLockDisabled={true}
            />
          }
          {
            props.state.initialItemState.value.DualUse === "No" &&
            <Label name='ITAR' info={props.state.initialItemState.value.ITAR} />
          }
          { props.state.initialItemState.value.DualUse === "Yes" &&
            props.state.initialItemState.value.ECCNStatus === ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW &&
            <Label name='Review status' info={props.state.initialItemState.value.ReviewStatus} />
          }
          
          {<Label name="Last baselined by" info={props.state.lastBaselinedBy.value} />}
          {<Label name="Last baselined" info={formatStringToDate(props.state.lastBaselinedDate.value)} />}
          {<Label name='Item summary classification details' info={props.state.itemClassificationDetails.value} />}
          {<Label name="System ID" info={props.state.id.value} />}
          {<Label name="Subrecords" info={
            <div>
              {props.state.itemRefs.value?.map((i) =>  <p><ItemLink itemId={i} /></p>)}
            </div>
          }/>}
        </ColumnLayout>
      </Container>
    )

    const readOnlyView = (
      <Container
        header={
          <Header variant="h2">
            Summary
          </Header>
        }
      >
        <ColumnLayout columns={3} borders="vertical">
          {<Label name="Item full name" info={props.state.itemFullName.value} />}
          {<Label name="Internal code name" info={props.state.internalCodeName.value} />}
          {<Label name="Item version" info={props.state.customerDefinedVersion.value} />}
          {<Confidential confidential={props.state.highlyConfidential.value} />}
          {<Label name="Item type" info={props.state.itemType.value} />}
          { props.state.initialItemState.value.DualUse === "Yes" &&
            <ECCNLabel 
              ECCNStatus={props.state.initialItemState.value.ECCNStatus} 
              ECCN={props.state.initialItemState.value.ECCN} 
              ECCNLocked={props.state.initialItemState.value.ECCNLocked}
              ECCNLockDisabled={true} 
            />
          }
          {
            props.state.initialItemState.value.DualUse === "No" &&
            <Label name='ITAR' info={props.state.initialItemState.value.ITAR} />
          }
          { props.state.initialItemState.value.DualUse === "Yes" &&
            props.state.initialItemState.value.ECCNStatus === ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW &&
            <Label name='Review status' info={props.state.initialItemState.value.ReviewStatus} />
          }
          {<Label name="Last baselined by" info={props.state.lastBaselinedBy.value} />}
          {<Label name="Last baselined" info={formatStringToDate(props.state.lastBaselinedDate.value)} />}
          {<Label name="System ID" info={props.state.id.value} />}
          {<Label name="Subrecords" info={
            <div>
              {props.state.itemRefs.value?.map((i) =>  <p><ItemLink itemId={i} /></p>)}
            </div>
          }/>}
        </ColumnLayout>
      </Container>
    )

    const editView = (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant='link' loading={props.state.loading.value} onClick={() => props.state.onCancel()}>Cancel</Button>
                <Button variant='primary' loading={props.state.loading.value} onClick={() => props.state.onSave()}>Save</Button>
              </SpaceBetween>
            }
          >
            Summary
          </Header>
        }
      >
        <ColumnLayout>
          <ColumnLayout columns={1}>
            <FormField id="Item_Full_Name" label={<div><span>Item Full Name</span></div>}
              description="The name of the product, service, or technology. If the Item has not yet been officially named, use the internal code name"
              errorText={props.state.itemFullName.value ? props.state.itemFullName.errorText : REQUIRED_MESSAGE}
            >
              <Input
                value={props.state.itemFullName.value}
                onChange={event => {
                  props.state.itemFullName.setValue(event.detail.value);
                }}
              />
            </FormField>
            <FormField id="Internal_Code_Name" label={<div><span>Internal Code Name</span></div>}
              description="The code name used internally for the product, service, or technology"
              errorText={
                props.state.internalCodeName.value ?
                  ItemDetailModel.UI.Container.BasicContainerState.getErrorMessage("CodeName", props.state)
                  : REQUIRED_MESSAGE
              }
            >
              <Input
                value={props.state.internalCodeName.value}
                onChange={event => {
                  props.state.internalCodeName.setValue(event.detail.value);
                }}
              />
            </FormField>
          </ColumnLayout>
          <ColumnLayout columns={1}>
            <FormField id="version" label={<div><span>Item version<i> - optional</i></span></div>}
              description="The current version of the product, service, or technology"
            >
              <Input
                value={props.state.customerDefinedVersion.value}
                onChange={event => {
                  props.state.customerDefinedVersion.setValue(event.detail.value);
                }}
              />
            </FormField>
            <FormField id="highly_confidential" label={<div><span>Highly Confidential</span></div>}
              description={<span>Clicking "yes" will limit access to this record to employees in this <a href="https://permissions.amazon.com/a/team/cript-leadership" target="_blank">permission group</a>, as well as any users specified in the "Product Manager", "Business Line Lawyer", and "Additional Contributors" fields.</span>}
              errorText={props.state.highlyConfidential.value ? props.state.highlyConfidential.errorText : REQUIRED_MESSAGE}
            >
              <RadioGroup
                items={highlyConfidentialOptions}
                value={props.state.highlyConfidential.value}
                onChange={(event) => {
                  props.state.highlyConfidential.setValue(event.detail.value as "Yes" | "No" | "")
                }}
              ></RadioGroup>
            </FormField>
            <FormField label={<div><span>Subrecords</span></div>}>
              <ItemMultiSelect
                itemRefs={props.state.itemRefs.value}
                onChange={(itemRefs) => props.state.itemRefs.setValue(itemRefs)}
              />
            </FormField>
          </ColumnLayout>
        </ColumnLayout>
      </Container>
    )

    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const Contact = (props: { state: ItemDetailModel.UI.Container.ContactState }) => {
    const defaultView = (
      <Container
        className="contacts-container"
        header={
          <Header variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => props.state.status.setValue(
                  ItemDetailModel.UI.Container.Status.Edit)
                }>Edit</Button>
              </SpaceBetween>
            }
          >
            Contacts
          </Header>
        }
      >
        <ColumnLayout columns={4} borders='vertical'>
          <EmployeeLabel
            title={"Product Manager"}
            alias={props.state.productManager.value.alias}
            content={`${props.state.productManager.value.name} (${props.state.productManager.value.costCenterId})`}
          />
          <EmployeeLabel
            title={"Business Line Lawyer"}
            alias={props.state.businessLineLawyer.value.alias}
            content={`${props.state.businessLineLawyer.value.name}`}
          />
          {props.state.otherContributors.value &&
            props.state.otherContributors.value.map((contributor) =>
            <EmployeeLabel
                title={contributor.Role}
                alias={contributor.Alias}
                content={
                  contributor.Name || contributor.Alias
                  // Fallback display alias when Name undefined
                }
              />
            )}
          <div>
            <div className="awsui-util-label">Owner Team</div>
            <TeamLink teamName={props.state.ownerTeam.value}/>
          </div>
        </ColumnLayout>
      </Container>
    );

    const editView = (
      <Container
        className="contacts-container"
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => {
                    props.state.onCancel();
                  }}
                  loading={props.state.loading.value}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  loading={props.state.loading.value}
                  disabled={(function () {
                    if (
                      props.state.otherContributors.value?.find(
                        (e) => !e.Alias || !e.Role
                      )
                    ) {
                      // Has invalid additonal contributor
                      return true;
                    }
                    return false;
                  })()}
                  onClick={() => {
                    if (
                      props.state.productManager.value.alias &&
                      props.state.businessLineLawyer.value.alias
                    ) {
                      props.state.onSave();
                    }
                  }}
                >
                  Save
                </Button>
              </SpaceBetween>
            }
          >
            Contacts
          </Header>
        }
      >
        <div>
          <ColumnLayout columns={1}>
            <FormField
              id="Product_manager"
              label={
                <div>
                  <span>Product Manager</span>
                </div>
              }
              errorText={
                props.state.productManager.value.alias ? "" : REQUIRED_MESSAGE
              }
            >
              <EmployeeSuggestions
                key={props.state.productManager.value.alias}
                onSelect={async (selected: { alias: string; name: string }) => {
                  props.state.productManager.setValue({
                    alias: selected.alias,
                    name: selected.name,
                    costCenterId: await getEmployeeSummary(selected.alias).then(
                      (result: {
                        data: {
                          costCenterId: string;
                        };
                      }) => {
                        return result.data.costCenterId;
                      }
                    )
                  });
                }}
                value={props.state.productManager.value.alias}
              />
            </FormField>
            <FormField
              id="business_lawyer"
              label={
                <div>
                  <span>Business Line Lawyer</span>
                </div>
              }
              errorText={
                props.state.businessLineLawyer.value.alias
                  ? ""
                  : REQUIRED_MESSAGE
              }
            >
              <EmployeeSuggestions
                key={props.state.businessLineLawyer.value.alias}
                onSelect={(selected) =>
                  props.state.businessLineLawyer.setValue({
                    alias: selected.alias,
                    name: selected.name
                  })
                }
                value={props.state.businessLineLawyer.value.alias}
              />
            </FormField>
            <FormField
              id="additional_contributor"
              label={
                <div>
                  <span>
                    Additional Contributors<i> - optional</i>
                  </span>
                </div>
              }
              description=" In addition to the Product Manager and Business Line Lawyer for this Item, the following individuals can view all Item details and perform any actions on this Item. "
            >
              <AdditionalContacts
                items={props.state.otherContributors.value}
                customOnChange={(value) => {
                  console.log("value from parent", value);
                  props.state.otherContributors.setValue(value);
                }}
              />
            </FormField>
            <FormField
              label={
                <div>
                  <span>Owner Team</span>
                </div>
              }
              description={
                <>
                  <div>Specify the owner team</div>
                  <div>
                    Verify your team is correct at{" "}
                    <Link
                      external
                      fontSize="body-s"
                      href={`https://permissions.amazon.com/a/team/${props.state.ownerTeam.value}`}
                    >
                      {`https://permissions.amazon.com/a/team/${props.state.ownerTeam.value}`}
                    </Link>
                  </div>
                </>
              }
            >
              <Input
                value={props.state.ownerTeam.value}
                onChange={(e) => {
                  props.state.ownerTeam.setValue(e.detail.value);
                }}
              />
            </FormField>
          </ColumnLayout>
        </div>
      </Container>
    );

    const readOnlyView = (
      <Container
        className="contacts-container"      
        header={
          <Header variant="h2">
            Contacts
          </Header>
        }
      >
        <ColumnLayout columns={4} borders='vertical'>
          <EmployeeLabel
            title={"Product Manager"}
            alias={props.state.productManager.value.alias}
            content={`${props.state.productManager.value.name} (${props.state.productManager.value.costCenterId})`}
          />
          <EmployeeLabel
            title={"Business Line Lawyer"}
            alias={props.state.businessLineLawyer.value.alias}
            content={`${props.state.productManager.value.name}`}
          />
          {props.state.otherContributors.value &&
            props.state.otherContributors.value.map((contributor) =>
              <EmployeeLabel
                title={contributor.Role}
                alias={contributor.Alias}
                content={`${contributor.Name}`}
              />
            )}
          <div>
            <div className="awsui-util-label">Owner Team</div>
            <TeamLink teamName={props.state.ownerTeam.value}/>
          </div>
        </ColumnLayout>
      </Container>
    );
    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const Detail = (props: { state: ItemDetailModel.UI.Container.DetailState }) => {
    const mapToYesNo = (value: string) => {
      const mapping = {
        "yes": "Yes",
        "no": "No",
        "": "",
      }
      return mapping[value.toLowerCase()]
    }
    const defaultView = (
      <Container
        className="details-container"
        header={
          <Header variant="h2"
            actions={<SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => props.state.status.setValue(ItemDetailModel.UI.Container.Status.Edit)}>
                Edit
              </Button>
            </SpaceBetween>}
          >
            Details
          </Header>
        }
      >
        <ColumnLayout columns={4} borders="vertical">
          {<Label name='Description' info={props.state.description.value} />}
          {<Label name='Documentation' info={TextLink(props.state.documentation.value)} />}
          {<Label name='Military use' info={mapToYesNo(props.state.militaryUse.value)} />}
          {<Label name='Research project' info={mapToYesNo(props.state.researchProject.value)} />}
          <FormField 
            label='CET (Critical and Emerging Technology Categories)'
            errorText={props.state.cet.value?.length < 1 && 'Required'}>
            {props.state.cet.value.map(v => <p>{v}</p>)}
          </FormField>
        </ColumnLayout>
      </Container>
    )

    const editView = (
      <Container
        className="details-container"
        header={
          <Header variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  loading={props.state.loading.value}
                  variant='link'
                  onClick={() => {
                    props.state.onCancel()
                  }}>Cancel</Button>
                <Button
                  variant='primary'
                  onClick={() => props.state.onSave()}
                  loading={props.state.loading.value}
                >
                  Save
                </Button>
              </SpaceBetween>
            }
          >
            Details
          </Header>
        }
      >
        <ColumnLayout columns={1}>
          <FormField id="description" label={<div><span>Description</span></div>}
            description='Provide a description of the Item, including what you understand to be the primary use/function.'
            errorText={props.state.description.value ? "" : REQUIRED_MESSAGE}
          >
            <Input
              value={props.state.description.value}
              onChange={event => {
                props.state.description.setValue(event.detail.value);
              }
              }
            />
          </FormField>

          <FormField id="online_documentation" label={<div><span>Online Documentation<i> - optional</i></span></div>}
            description="Provide a link to the item's online information">
            <Input
              value={props.state.documentation.value}
              onChange={event => {
                props.state.documentation.setValue(event.detail.value);
              }}
            />
          </FormField>
          <FormField id="military_use" label={<div><span>Military Use</span></div>}
            description={<div>
              Is either of the following true?
              <ol>
                <li>The Item is designed specifically or primarily for military use, or</li>
                <li>Likely customers for this Item are primarily military or defense organizations even if the Item is also intended for broader commercial use.</li>
              </ol>
            </div>}
            errorText={props.state.militaryUse.value ? "" : REQUIRED_MESSAGE}
          >

            <RadioGroup items={militaryUseOptions}
              value={mapToYesNo(props.state.militaryUse.value)}
              onChange={(event) => {
                props.state.militaryUse.setValue(event.detail.value as "Yes" | "No" | "");
              }} />
          </FormField>

          <FormField id="research_project" label={<div><span>Research Project</span></div>}
            description={<div>Is the Item part of a basic or applied research project intended to be published and shared broadly within the scientific comunity?<br />
              Answers will generally be "No", but may be "Yes". if e.g., you're working with a university on your Item and the results of that work
              will be published in academic journals or elsewhere in the research community.
            </div>}
            errorText={props.state.researchProject.value ? "" : REQUIRED_MESSAGE}
          >
            <RadioGroup items={researchProjectOptions}
              value={mapToYesNo(props.state.researchProject.value)}
              onChange={(event) => {
                props.state.researchProject.setValue(event.detail.value as "Yes" | "No" | "")
              }} />
          </FormField>
          <FormField
            id="cet"
            label={<div><span>Please select which category best fits your Item</span></div>}
            errorText={props.state.cet.value?.length < 1 && 'Required'}
          >
            <Multiselect
              options={cetMultiOptions.map((o) => { return {value: o.value, label: o.value, description: o.description}})}
              selectedOptions={props.state.cet.value.map((v) => { 
                return {value: v, label: v }
              })}
              onChange={event => {
                let selected = event.detail.selectedOptions.map(o => o.value)
                if (selected[selected.length - 1] === cetNotApplicableOption) {
                  props.state.cet.setValue([cetNotApplicableOption]);
                } else if (props.state.cet.value.includes(cetNotApplicableOption)) {
                  let updatedCET = selected.filter(value => value !== cetNotApplicableOption);
                  props.state.cet.setValue(updatedCET);
                } else {
                  props.state.cet.setValue(selected)
                }
              }}
            />
          </FormField>
        </ColumnLayout>
      </Container>
    );

    const readOnlyView = (
      <Container
        className="details-container"
        header={
          <Header variant="h2">
            Details
          </Header>
        }
      >
        <ColumnLayout columns={4} borders="vertical">
          {<Label name='Description' info={props.state.description.value} />}
          {<Label name='Documentation' info={TextLink(props.state.documentation.value)} />}
          {<Label name='Military use' info={mapToYesNo(props.state.militaryUse.value)} />}
          {<Label name='Research project' info={mapToYesNo(props.state.researchProject.value)} />}
          {<Label name='CET (Critical and Emerging Technology Categories)'
            info={props.state.cet.value.map(v => <p>{v}</p>)}
          />}
        </ColumnLayout>
      </Container>
    );

    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const AmazonDependency = (props: { state: ItemDetailModel.UI.Container.AmazonDependencyState }) => {
    const columnDefinitions = [
      {
        id: "product_name",
        header: "Name of Amazon service or other product (e.g., CloudHSM, KMS, S3)",
        cell: (item: Item.AmazonDependency) => item.product_name || "-",
        width: 200
      },
      {
        id: "version_no",
        header: "Version #",
        cell: (item: Item.AmazonDependency) => item.version_no || "-",
        width: 200
      },
      {
        id: "product_interaction_description",
        header: "Briefly describe how this service or product is used in or interacts with your Item",
        cell: (item: Item.AmazonDependency) => item.product_interaction_description || "-",
        width: 200
      }
    ]
    const defaultView = (
      <Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={props.state.amazonDependency.value}
        header={
          <Header variant="h2"
            actions={
              <Button onClick={() => props.state.status.setValue(
                ItemDetailModel.UI.Container.Status.Edit
              )}>
                Edit
              </Button>
            }
          >
            Amazon services or products (#)
          </Header>
        }
        empty={<div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>
              No related Amazon services, products, or hardware
            </b>
          </div>
          <p className="awsui-util-mb-s">
            No resources to display.
          </p>
        </div>}
      ></Table>
    )
    const editView = (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button
                  variant="primary"
                  loading={props.state.loading.value}
                  onClick={() => props.state.onSave()}
                  disabled={(function () {
                    const uniqueProductNames = new Set<string>();
                    props.state.amazonDependency.value.forEach((e) =>
                      uniqueProductNames.add(e.product_name)
                    );
                    if (
                      uniqueProductNames.size <
                      props.state.amazonDependency.value.length
                    ) {
                      // There are duplicated product names
                      return true;
                    }
                    return false;
                  })()}
                >
                  Save
                </Button>
                <Button
                  loading={props.state.loading.value}
                  onClick={() => props.state.onCancel()}
                >
                  Cancel
                </Button>
              </SpaceBetween>
            }
          >
            Amazon services or products (#)
          </Header>
        }
      >
        <div>
          <RepeaterTable
            field="AmazonDependencies"
            handleChange={props.state.amazonDependency.setValue}
            columnDefinitions={[
              {
                header:
                  "Name of Amazon service or other product (e.g., CloudHSM, KMS, S3)",
                id: "product_name",
                cell: (item) => item.product_name,
                errorText: (item, items) => {
                  const matches = items.filter(
                    (e) => e.product_name === item.product_name
                  );
                  if (matches.length > 1) {
                    return "Must be unique";
                  }
                  return undefined;
                },
                width: "40%"
              },
              {
                header: "Version #",
                id: "version_no",
                cell: (item) => item.version_no,
                width: "15%"
              },
              {
                header:
                  "Briefly describe how this service or product is used in or interacts with your Item",
                id: "product_interaction_description",
                cell: (item) => item.product_interaction_description,
                width: "45%"
              }
            ]}
            items={props.state.amazonDependency.value}
          />
        </div>
      </Container>
    );
    const readOnlyView = (
      <Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        items={props.state.amazonDependency.value}
        header={
          <Header variant="h2">
            Amazon services or products (#)
          </Header>
        }
        empty={<div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>
              No related Amazon services, products, or hardware
            </b>
          </div>
          <p className="awsui-util-mb-s">
            No resources to display.
          </p>
        </div>}
      ></Table>
    )

    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const ThirdPartyDependency = (props: { state: ItemDetailModel.UI.Container.ThirdPartyDependencyState }) => {
    const columnDefinitions = [
      {
        id: "third_party_name",
        header: "Name of third party service, software, product, etc.",
        cell: (item: Item.ThirdPartyDependency) => item.third_party_name || "-",
        width: 200
      },
      {
        id: "version",
        header: "Version #",
        cell: (item: Item.ThirdPartyDependency) => item.version || "-",
        width: 200
      },
      {
        id: "url",
        header: "URL with description",
        cell: (item: Item.ThirdPartyDependency) => item.url || "-",
        width: 200
      }
    ]
    const defaultView = (
      <div>
        <Table
          loadingText="Loading resources"
          columnDefinitions={columnDefinitions}
          items={props.state.thirdPartyDependency.value}
          header={
            <Header variant="h2"
              actions={
                <Button onClick={() => props.state.status.setValue(
                  ItemDetailModel.UI.Container.Status.Edit
                )}>Edit</Button>
              }
            >
              Amazon services or products (#)
            </Header>
          }
          empty={<div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>
                No third-party (i.e., non-Amazon) services, products, hardware, or software
              </b>
            </div>
            <p className="awsui-util-mb-s">
              No resources to display.
            </p>
          </div>}
        ></Table>
      </div>
    );

    const editView = (
      <Container
        header={
          <Header variant="h2"
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button
                  variant="primary"
                  loading={props.state.loading.value}
                  onClick={() => props.state.onSave()}
                  disabled={(function () {
                    const uniqueNames = new Set<string>();
                    props.state.thirdPartyDependency.value.forEach((e) =>
                      uniqueNames.add(e.third_party_name)
                    );
                    if (
                      uniqueNames.size <
                      props.state.thirdPartyDependency.value.length
                    ) {
                      // There are duplicated names
                      return true;
                    }
                    return false;
                  })()}
                >
                  Save
                </Button>
                <Button
                  loading={props.state.loading.value}
                  onClick={() => props.state.onCancel()}
                >
                  Cancel
                </Button>
              </SpaceBetween>
            }
          >
            Amazon services or products (#)
          </Header>
        }
      >
        <RepeaterTable
          field='ThirdPartyDependencies'
          handleChange={(items)=>{
            props.state.thirdPartyDependency.setValue(items);
          }}
          columnDefinitions={[
            {
              header: "Name of third party service, software, product, etc.",
              id: "third_party_name",
              cell: item => item.third_party_name,
              errorText: (item, items) => {
                const matches = items.filter(
                  (e) => e.third_party_name === item.third_party_name
                );
                if (matches.length > 1) {
                  return "Must be unique";
                }
                return undefined;
              },
              width: '40%'
            },
            {
              header: "Version #",
              id: "version",
              cell: item => item.version,
              width: '15%'
            },
            {
              header: "URL with description",
              id: "url",
              cell: item => item.url,
              width: '45%'
            }
          ]}
          items={props.state.thirdPartyDependency.value}
        />
      </Container>
    )

    const readOnlyView = (
      <div>
        <Table
          loadingText="Loading resources"
          columnDefinitions={columnDefinitions}
          items={props.state.thirdPartyDependency.value}
          header={
            <Header variant="h2">
              Third-party services or products (#)
            </Header>
          }
          empty={<div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>
                No third-party (i.e., non-Amazon) services, products, hardware, or software
              </b>
            </div>
            <p className="awsui-util-mb-s">
              No resources to display.
            </p>
          </div>}
        ></Table>
      </div>
    )

    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const RelatedDocument = (props: { state: ItemDetailModel.UI.Container.RelatedDocumentState }) => {
    const defaultView =
      <FileAttachments
        filesData={props.state.files.value}
        uploadFile={props.state.uploadAsset}
        readFile={props.state.readAsset}
        deleteFile={props.state.deleteAsset}
        title={<div>Related documents {props.state.loading.value && <Spinner />} </div>}
      />

    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
      </div>
    )
  }

  export const AdminFiles = (props: { state: ItemDetailModel.UI.Container.AdminDocumentState }) => {
    const defaultView =
      <FileAttachments
        filesData={props.state.adminFiles.value}
        uploadFile={props.state.uploadAsset}
        readFile={props.state.readAsset}
        deleteFile={props.state.deleteAsset}
        title={<div>Admin documents {props.state.loading.value && <Spinner />} </div>}
      />

    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
      </div>
    )
  }


  export const Questionnaire = (
    props: { state: ItemDetailModel.UI.Container.QuestionnaireState }) => {
    const appState = store.getState()
    const helpPane = React.useContext(HelpPaneContext);
    const showHelpPane = (term: string) => helpPane.dispatch(
      { pane: Pane.QuestionPane, type: Type.Term, payload: term }
    );
    const questionList = props.state.questionnaire.value.map(
      (question, index) => {
        const questionTextLabelled =
          ItemDetailModel.UI.Container.QuestionTextLabelled.createByQuestionData(
            question.question,
            showHelpPane
          );
        const questionDisplayText = QuestionModel.highlightKeywords(
          question.question.DisplayText,
          appState.keywordObj.keywords,
          showHelpPane
        );
        const q = {
          ...question,
          questionTextLabelled: {
            ...questionTextLabelled,
            DisplayText: [`${index + 1}. `, ...questionDisplayText.result]
          }
        }
        if (q.question.Type === "SINGLE_SELECTION") {
          const singleSelectQuesitonProps = {
            questionItem: q,
            operation: {
              onEditQuestion: () => props.state.onEditQuestion(index),
              onAnswerQuestion: (answer: string) => props.state.onAnswerQuestion({ index, answer }),
              onRefreshQuestion: () => props.state.onRefreshQuestion(index),
              onCancel: () => props.state.onCancelOperation(index),
            }
          }
          return <ItemQuestionnaire.SingleSelectQuesiton {...singleSelectQuesitonProps} />
        }
        if (q.question.Type === "MULTI_SELECTION") {
          const multiSelectQuesitonProps = {
            questionItem: q,
            operation: {
              onEditQuestion: () => props.state.onEditQuestion(index),
              onAnswerQuestion: (answer: string[]) => props.state.onAnswerQuestion({ index, answer }),
              onRefreshQuestion: () => props.state.onRefreshQuestion(index),
              onCancel: () => props.state.onCancelOperation(index),
            }
          }
          return <ItemQuestionnaire.MultiSelectQuesiton {...multiSelectQuesitonProps} />
        }
        if (q.question.Type === "TEXT") {
          const textQuestionProps = {
            questionItem: q,
            operation: {
              onEditQuestion: () => props.state.onEditQuestion(index),
              onAnswerQuestion: (answer: string) => props.state.onAnswerQuestion({ index, answer }),
              onRefreshQuestion: () => props.state.onRefreshQuestion(index),
              onCancel: () => props.state.onCancelOperation(index),
            }
          }
          return <ItemQuestionnaire.TextQuesiton {...textQuestionProps} />
        }
      }
    ).map((question, index) => {
      if (index % 2 === 0) {
        return (
          <div>
            <Box padding={{horizontal: "s", vertical: "s"}}>
              {question}
            </Box>
          </div>
        )
      }
      return (
        <div style={{ backgroundColor: '#f2f3f3' }}>
          <Box padding={{horizontal: "s", vertical: "s"}}>
            {question}
          </Box>
        </div>
      )
    })

    return (
      <Container
        disableContentPaddings
        className="questionnaire-container"
        header={<>
          Provide answers to the following question(s)
          <Link
            variant="info"
            href="javascript:void(0);"
            onFollow={() => helpPane.dispatch({ pane: Pane.QuestionPane, type: Type.Info, payload: '' })}
            className="pad-left-8px"
          >Info</Link>
        </>}
      >
        {questionList}
      </Container>
    )
  }

  export const Classification = (props: {
    state: ItemDetailModel.UI.Container.ClassificationState
  }) => {
    const ECCNStatusOption = [
      {
        "text": ECCN_STATUS.PENDING_QUESTIONNAIRE_COMPLETION,
        "id": ECCN_STATUS.PENDING_QUESTIONNAIRE_COMPLETION,
        "disabled": false
      },
      {
        "text": ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW,
        "id": ECCN_STATUS.PENDING_EXPORT_COMPLIANCE_REVIEW,
        "disabled": false
      },
      {
        "text": ECCN_STATUS.ACTIVE,
        "id": ECCN_STATUS.ACTIVE,
        "disabled": false
      }
    ]

    const ReviewStatusOptions = [
      {
        "text": REVIEW_STATUS.PENDING,
        "id": REVIEW_STATUS.PENDING,
        "disabled": false
      },
      {
        "text": REVIEW_STATUS.IN_REVIEW,
        "id": REVIEW_STATUS.IN_REVIEW,
        "disabled": false
      },
      {
        "text": REVIEW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
        "id": REVIEW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
        "disabled": false
      },
      {
        "text": REVIEW_STATUS.ON_HOLD,
        "id": REVIEW_STATUS.ON_HOLD,
        "disabled": false
      },
      {
        "text": REVIEW_STATUS.RESOLVED,
        "id": REVIEW_STATUS.RESOLVED,
        "disabled": false
      },
      {
        "text": REVIEW_STATUS.NO_STATUS,
        "id": REVIEW_STATUS.NO_STATUS,
        "disabled": false
      },
    ];

    const dualUseOptions = [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'No',
        label: 'No'
      },
    ]

    const ECCNColumn = (
      <>
        {
          props.state.dualUse.value === "Yes" &&
          <ContentLayout
            header={
              <Header
                variant="h3"
              >
                ECCN Classification Details
              </Header>
            }
          >
            {
              <ColumnLayout columns={2} borders="vertical">
                <ECCNLabel 
                  ECCNStatus={props.state.initialItemState.value.ECCNStatus} 
                  ECCN={props.state.initialItemState.value.ECCN} 
                  ECCNLocked={props.state.initialItemState.value.ECCNLocked}
                  ECCNLockDisabled={true}
                  onChange={({detail}) => {
                    updateECCNLock(props.state.initialItemState.value.Id, detail.checked)
                      .then((res) => {
                        props.state.ECCNLocked.setValue(detail.checked)
                        props.state.initialItemState.setValue(Builder<Item.Data>(props.state.initialItemState.value).ECCNLocked(detail.checked).build())
                      })
                  }}
                />
                <Label name='ECCN type' info={props.state.ECCNType.value} />
                <Label name='Temporary ECCN' 
                  info={
                    <SpaceBetween direction="horizontal" size={"s"}>
                    <>{props.state.initialItemState.value.TemporaryECCN}</>
                    {
                      props.state.initialItemState.value.TemporaryECCN &&  
                      <Button
                        variant="normal"
                        loading={props.state.loading.value} 
                        onClick={() => {
                          props.state.confirmECCNPromotionModalState.basicModalState.visible.setValue(true)
                        }}
                      >
                        Promote
                      </Button>  
                    }
                  </SpaceBetween>
                  } 
                />
                <Label name='Item classification details' info={props.state.itemClassificationDetails.value} />
                <Label name='Review status' info={props.state.reviewStatus.value} />
                <Label name='15 CFR 740.17' info={props.state.CFR740_17.value} />
              </ColumnLayout>
            }
          </ContentLayout>
        }

        {
          props.state.dualUse.value === "No" && 
          <ContentLayout
            header={
              <Header
                variant="h3"
              >
                ITAR Classification Details
              </Header>
            }
          >
            <ColumnLayout columns={2}>
              <Label name='ITAR' info={props.state.itar.value} />
              <Label name='Manual classification details' info={props.state.manualClassificationDetail.value} />
              <Label name='Item classification details' info={props.state.itemClassificationDetails.value} />
              <Label name='Review status' info={props.state.reviewStatus.value} />
              <Label name='ECCN Status' info={props.state.ECCNStatus.value} />
            </ColumnLayout>
          </ContentLayout>
        }
      </>
    )

    const otherClassifications = (
      <ContentLayout
        header={
          <Header
            variant="h3"
          >
            Other classifications
          </Header>
        }
      >
        <ColumnLayout columns={1} borders="vertical">
          <Label name='Singapore Strategic Goods codes' info={props.state.singaporeStrategicGoodsCodes.value} />
        </ColumnLayout>
      </ContentLayout>
    )

    const defaultView = (
      <Container
        header={
          <Header variant="h2"
            actions={
              <Button onClick={() => props.state.onEdit()}>Edit</Button>
            }
          >
            Classification
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Label name='Dual Use' info={props.state.dualUse.value} />
          <ColumnLayout columns={2} borders="vertical">
            {ECCNColumn}
            {otherClassifications}
          </ColumnLayout>
        </SpaceBetween>
      </Container>
    )

    const editView = (
      <Container
        header={
          <Header variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant='link' loading={props.state.loading.value} onClick={() => props.state.onCancel()}>Cancel</Button>
                <Button variant='primary' loading={props.state.loading.value} onClick={() => props.state.onSave()}>Save</Button>
              </SpaceBetween>
            }
          >
            Classification
          </Header>
        }
      >
        <h4>ECCN</h4>
        <ColumnLayout columns={1} className='awsui-util-pv-s'>
          <FormField id="ECCN" label={<div><span>ECCN</span></div>} >
            <Input
              value={props.state.ECCN.value}
              onChange={event => {
                props.state.onManualSetECCN(event.detail.value)
              }}
            />
            <div className='awsui-util-pv-s'>
              <p>ECCN Lock: </p>
              <Toggle 
                checked={props.state.ECCNLocked.value}
                onChange={
                  (event) => props.state.ECCNLocked.setValue(event.detail.checked)
                }
              >
                {props.state.ECCNLocked.value ? "Locked" : "Not Locked"}
              </Toggle>
              <p>ECCN status: </p>
              <ButtonDropdown
                items={ECCNStatusOption}
                onItemClick={
                  (event) => props.state.ECCNStatus.setValue(event.detail.id as ECCN_STATUS)
                }>
                {props.state.ECCNStatus.value ? props.state.ECCNStatus.value : 'ECCN Status'}
              </ButtonDropdown>
              <p>Review status: </p>
              <ButtonDropdown
                items={ReviewStatusOptions}
                onItemClick={(event) => props.state.reviewStatus.setValue(event.detail.id as REVIEW_STATUS)}
              >
                {props.state.reviewStatus.value}
              </ButtonDropdown>
            </div>
          </FormField>
          <FormField
            id="15CFR740.17"
            label={<div><span>15 CFR 740.17</span></div>}>
            <Input
              value={props.state.CFR740_17.value}
              onChange={event => props.state.CFR740_17.setValue(event.detail.value)} />
          </FormField>
          <FormField id="ManualClassificationDetails" label={<div><span>Manual classification details</span></div>}
          >
            <Input
              value={props.state.manualClassificationDetail.value}
              onChange={event => props.state.manualClassificationDetail.setValue(event.detail.value)}
            />
          </FormField>
          <FormField id="ItemClassificationDetails" label={<div><span>Item summary classification details</span></div>}
          >
            <Textarea
              value={props.state.itemClassificationDetails.value}
              onChange={event => props.state.itemClassificationDetails.setValue(event.detail.value)}
            />
          </FormField>
        </ColumnLayout>
        <h4>Other Classifications</h4>
        <ColumnLayout columns={1}>
          <FormField id="SingaporeStrategicGoodsCodes" label={<div><span>Singapore Strategic Goods codes</span></div>}>
            <Input
              value={props.state.singaporeStrategicGoodsCodes.value}
              onChange={event => props.state.singaporeStrategicGoodsCodes.setValue(event.detail.value)}
            />
          </FormField>
          <FormField id="DualUse" label={<div><span>Dual Use</span></div>}>
            <RadioGroup
              items={dualUseOptions}
              value={props.state.dualUse.value}
              onChange={(event) => {
                props.state.dualUse.setValue(event.detail.value as "Yes" | "No" | "")
              }}
            />
          </FormField>
          <FormField id="ITAR" label={<div><span>ITAR</span></div>}>
            <Input
              value={props.state.itar.value}
              onChange={event => props.state.itar.setValue(event.detail.value)}
            />
          </FormField>
        </ColumnLayout>
      </Container>
    )

    const readOnlyView = (
      <Container
        header={
          <Header variant="h2">
            Classification
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Label name='Dual Use' info={props.state.dualUse.value} />
          <ColumnLayout columns={2} borders="vertical">
            {ECCNColumn}
            {otherClassifications}
          </ColumnLayout>
        </SpaceBetween>
      </Container>
    )

    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const Approval = (props: {
    state: ItemDetailModel.UI.Container.ApprovalState
  }) => {


    const radioGroupOptions = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ];

    const CCATSColumn = (
      <div>
        <h3>CCATS</h3>
        <div className="awsui-util-pt-l">
          <div className="field-container">
            <Label name="CCATS" info={props.state.CCATS.value} />
          </div>
          <div className="field-container">
            <Label name="CCATS date" info={props.state.CCATSDate.value} />
          </div>
        </div>
      </div>
    );
    
    const semiAnnualReporting = (
      <div>
        <h3>Semi-annual reporting</h3>
        <div className="awsui-util-pt-l">
          <div className="field-container">
            <Label name="Subject to semi-annual reporting" info={props.state.semiAnnualReporting.value} />
          </div>
          <div className="field-container">
            <Label name="Semi-annual report date" info={props.state.semiAnnualReportingDate.value} />
          </div>
        </div>
      </div>
    );
    
    const annualReporting = (
      <div>
        <h3>Annual reporting</h3>
        <div className="awsui-util-pt-l">
          <div className="field-container">
            <Label name="Subject to annual reporting" info={props.state.annualReporting.value} />
          </div>
          <div className="field-container">
            <Label name="ENC ItemType Descriptor" info={ECNItemTypeDescriptor.find(item => item.value === props.state.ECNType.value)?.label || props.state.ECNType.value} />
          </div>
          <div className="field-container">
            <Label name="Annual report date" info={props.state.annualReportingDate.value} />
          </div>
        </div>
      </div>
    );
    
    const defaultView = (
      <Container
        header={
          <Header variant="h2"
            actions={
              <Button onClick={() => props.state.onEdit()}>Edit</Button>
            }
          >
            Approvals and obligations
          </Header>
        }
      >
        <ColumnLayout columns={3} borders="vertical">
          {CCATSColumn}
          {semiAnnualReporting}
          {annualReporting}
        </ColumnLayout>
      </Container>
    )

    const readOnlyView = (
      <Container
        header={
          <Header variant="h2">
            Approvals and obligations
          </Header>
        }
      >
        <ColumnLayout columns={3} borders="vertical">
          {CCATSColumn}
          {semiAnnualReporting}
          {annualReporting}
        </ColumnLayout>
      </Container>
    )

    const editView = (
      <Container
        header={
          <Header variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button loading={props.state.loading.value} variant='link' onClick={() => {
                  props.state.onCancel()
                }}>Cancel</Button>
                <Button loading={props.state.loading.value} variant='primary' onClick={() => {
                  props.state.onSave()
                }}>Save</Button>
              </SpaceBetween>
            }
          >
            Approvals and obligations
          </Header>
        }
      >
        <div>
          <ColumnLayout columns={1}>
            <h4>CCATS</h4>
            <FormField id="CCATS" label={<div><span>CCATS</span></div>}>
              <Input
                value={props.state.CCATS.value}
                onChange={event => props.state.CCATS.setValue(event.detail.value)
                }
              />
            </FormField>
            <FormField id="CCATSDate" label={<div><span>CCATS Date</span></div>}>
              <DatePicker
                placeholder="YYYY/MM/DD"
                todayAriaLabel="Today"
                nextMonthAriaLabel="Next month"
                previousMonthAriaLabel="Previous month"
                value={props.state.CCATSDate.value}
                onChange={event => props.state.CCATSDate.setValue(event.detail.value)
                }
              />
            </FormField>
            <h4>Semi-annual reporting</h4>
            <FormField id="Semi-annual reporting" label={<div><span>Subject to semi-annual reporting</span></div>}>
              <RadioGroup
                items={radioGroupOptions}
                value={props.state.semiAnnualReporting.value}
                onChange={event => props.state.semiAnnualReporting.setValue(event.detail.value)
                } />
            </FormField>
            <FormField id="SemiAnnualDate" label={<div><span>Semi-annual report date</span></div>}>
              <DatePicker
                placeholder="YYYY/MM/DD"
                todayAriaLabel="Today"
                nextMonthAriaLabel="Next month"
                previousMonthAriaLabel="Previous month"
                value={props.state.semiAnnualReportingDate.value}
                onChange={event => props.state.semiAnnualReportingDate.setValue(event.detail.value)
                }
              />
            </FormField>
            <h4>Annual reporting</h4>
            <FormField id="SubjectToAnnualReporting" label={<div><span>Subject to annual reporting</span></div>}
            >
              <RadioGroup
                items={radioGroupOptions}
                value={props.state.annualReporting.value}
                onChange={event => props.state.annualReporting.setValue(event.detail.value)
                }
              />

            </FormField>
            {props.state.annualReporting.value === "Yes" && (
              <FormField id="ENCType" label={<div><span>ENC ItemType Descriptor</span></div>}>
                <Select
                  options={ECNItemTypeDescriptor.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  selectedOption={ECNItemTypeDescriptor.find((e) => e.value === props.state.ECNType.value)}
                  onChange={(event) => {
                    const selectedValue = event.detail.selectedOption.value;
                    props.state.ECNType.setValue(selectedValue);
                  }}
                />
              </FormField>
            )}
            <FormField id="AnnualReportDate" label={<div><span>Annual report date</span></div>}>
              <DatePicker
                placeholder="YYYY/MM/DD"
                todayAriaLabel="Today"
                nextMonthAriaLabel="Next month"
                previousMonthAriaLabel="Previous month"
                value={props.state.annualReportingDate.value}
                onChange={event => props.state.annualReportingDate.setValue(event.detail.value)
                }
              />
            </FormField>
          </ColumnLayout>
        </div>
      </Container>
    );
    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const BaselineExemption = (props: {
    state: ItemDetailModel.UI.Container.BaselinExemptionState
  }) => {
    const defaultView = (
      <Container
        header={
          <Header variant="h2"
            actions={
              <Button onClick={() => props.state.onEdit()}>Edit</Button>
            }
          >
            Baseline exemptions
          </Header>
        }
      >
        <ColumnLayout columns={3} borders="vertical">
          <Label name='Exempt from escalation' info={props.state.exemptFromEscalation.value} />
          <Label name='Exempt from baseline' info={props.state.exemptFromBaseline.value} />
          <Label name='Reason for baseline exemption' info={props.state.reasonForBaselineExemption.value} />
        </ColumnLayout>
      </Container>
    )

    const readOnlyView = (
      <Container
        header={
          <Header variant="h2">
            Baseline exemptions
          </Header>
        }
      >
        <ColumnLayout columns={3} borders="vertical">
          <Label name='Exempt from escalation' info={props.state.exemptFromEscalation.value} />
          <Label name='Exempt from baseline' info={props.state.exemptFromBaseline.value} />
          <Label name='Reason for baseline exemption' info={props.state.reasonForBaselineExemption.value} />
        </ColumnLayout>
      </Container>
    )

    const radioGroupOptions = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ];

    const editView = (
      <Container
        header={
          <Header variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant='link' onClick={() => {
                  props.state.onCancel()
                }}>Cancel</Button>
                <Button variant='primary' onClick={() => {
                  props.state.onSave()
                }}>Save</Button>
              </SpaceBetween>
            }
          >
            Baseline exemptions
          </Header>
        }
      >
        <div>
          <ColumnLayout columns={1}>
            <FormField id="exempt_from_escalation" label={<div><span>Exempt from escalations</span></div>}>
              <RadioGroup
                items={radioGroupOptions}
                value={props.state.exemptFromEscalation.value}
                onChange={(event) => {
                  props.state.exemptFromEscalation.setValue(event.detail.value)
                }}
              ></RadioGroup>
            </FormField>
            <FormField id="exempt_from_baseline" label={<div><span>Exempt from baseline</span></div>}>
              <RadioGroup
                items={radioGroupOptions}
                value={props.state.exemptFromBaseline.value}
                onChange={(event) => {
                  props.state.exemptFromBaseline.setValue(event.detail.value)
                }}
              ></RadioGroup>
            </FormField>

            <FormField id="ReasonForBaselineExemption" label={<div><span>Reason for baseline exemption</span></div>}
            >
              <Input
                value={props.state.reasonForBaselineExemption.value}
                onChange={event => {
                  props.state.reasonForBaselineExemption.setValue(event.detail.value)
                }
                }
              />
            </FormField>
          </ColumnLayout>
        </div>
      </Container>
    );
    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    )
  }

  export const Supplements = (props:
    { state: ItemDetailModel.UI.Container.CCATsSupplementState }) => {

    const CCATsList = (
      CCATsQuestions.map((question, index) => {
        const numberOfQuestion = index + 1;
        const displayText = numberOfQuestion + '. ' + question.question
        const label = <div style={{ color: '#687078' }}>{displayText}</div>
        const answer = <p>{props.state.CCATs.value[index] || '[No answer]'}</p>
        return (
          <FormField
            id={"" + index}
            label={label}
            stretch={true}
          >
            {answer}
          </FormField>
        )
      }).map((form, index) => {
        if (index % 2 === 0) {
          return (
              <Box padding="l">
                {form}
              </Box>
            )
        } else {
          return (
            <Box padding="l" className="gray-question-background">
              {form}
            </Box>
          )
        }
      })
    );

    const readOnlyView = (
      <Container
        header={
          <Header variant="h2">
            CCATS Supplement
          </Header>
        }
        disableContentPaddings
      >
        <div>
          {CCATsList}
        </div>
      </Container>
    );

    const defaultView = (
      <Container
        disableContentPaddings
        header={
          <Header variant="h2"
            actions={
              <Button onClick={() => props.state.onEdit()}>Edit</Button>
            }
          >
            CCATS Supplement
          </Header>
        }
      >
        <div>
          {CCATsList}
        </div>
      </Container>
    );

    const editView = (
      <Container
        disableContentPaddings
        header={
          <Header variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant='primary'
                  onClick={() => {
                    props.state.onSave()
                  }}
                  loading={props.state.loading.value}
                >
                  Save
                </Button>
                <Button
                  variant='link'
                  onClick={() => {
                    props.state.onCancel()
                  }}
                  loading={props.state.loading.value}
                >Cancel</Button>                
              </SpaceBetween>
            }
          >
            CCATS Supplement
          </Header>
        }
      >
        <div>
          {CCATsQuestions.map((question, index) => {
            const numberOfQuestion = index + 1;
            const displayText = numberOfQuestion + '. ' + question.question
            return <div> <FormField
              id={"" + index}
              label={displayText}
              stretch={true}
            />
              <div className='awsui-util-pt-l'>
                <Textarea value={props.state.CCATs.value[index]}
                  disabled={false}
                  onChange={
                    (event) => {
                      props.state.CCATs.setValue([
                        ...props.state.CCATs.value.map((c, i) => {
                          if (i === index) {
                            return event.detail.value;
                          } else {
                            return c;
                          }
                        })]);
                    }}
                />
              </div>
            </div>
          }).map((e, index) => {
            if (index % 2 === 0) {
              return (
                <div>
                  <Box padding={{horizontal: "s", vertical: "s"}}>
                  {e}
                  </Box>
                </div>
              )
            } else {
              return (
                <div style={{ backgroundColor: '#f2f3f3' }}>
                  <Box padding={{horizontal: "s", vertical: "s"}}>
                  {e}
                  </Box>
                </div>
              )
            }
          })}
        </div>
      </Container>
    )
    return (
      <div>
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Default && defaultView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.Edit && editView}
        {props.state.status.value === ItemDetailModel.UI.Container.Status.ReadOnly && readOnlyView}
      </div>
    );
  }

  export const RelatedAmazonDependenciesTable = ({ onChange, amazonDependencies }) => {
    return (
      <Container
        header={
          <Header variant="h2">
            Amazon services or products (#)
          </Header>
        }
      >
        <div>
          <RepeaterTable
            field='AmazonDependencies'
            handleChange={onChange}
            columnDefinitions={[{
              header: "Name of Amazon service or other product (e.g., CloudHSM, KMS, S3)",
              id: "product_name",
              cell: (item: Item.AmazonDependency) => item.product_name,
              errorText: (item, items) => {
                const matches = items.filter(
                  (e) => e.product_name === item.product_name
                );
                if (matches.length > 1) {
                  return "Must be unique";
                }
                return undefined;
              },
              width: '40%'
            },
            {
              header: "Version #",
              id: "version_no",
              cell: (item: Item.AmazonDependency) => item.version_no,
              width: '15%'
            },
            {
              header: "Briefly describe how this service or product is used in or interacts with your Item",
              id: "product_interaction_description",
              cell: (item: Item.AmazonDependency) => item.product_interaction_description,
              width: '45%'
            }]}
            items={amazonDependencies}
          />
        </div>
      </Container>
    )
  }

  export const ThirdPartyDependenciesTable = ({ onChange, thirdPartyDependencies }) => {
    return (
      <Container
        header={
          <Header variant="h2">
            Amazon services or products (#)
          </Header>
        }
      >
        <RepeaterTable
          field='ThirdPartyDependencies'
          handleChange={onChange}
          columnDefinitions={[
            {
              header: "Name of third party service, software, product, etc.",
              id: "third_party_name",
              cell: (item: Item.ThirdPartyDependency) => item.third_party_name,
              errorText: (item, items) => {
                const matches = items.filter(
                  (e) => e.third_party_name === item.third_party_name
                );
                if (matches.length > 1) {
                  return "Must be unique";
                }
                return undefined;
              },
              width: '40%'
            },
            {
              header: "Version #",
              id: "version",
              cell: (item: Item.ThirdPartyDependency) => item.version,
              width: '15%'
            },
            {
              header: "URL with description",
              id: "url",
              cell: (item: Item.ThirdPartyDependency) => item.url,
              width: '45%'
            }
          ]}
          items={thirdPartyDependencies}
        />
      </Container>
    );
  }
}