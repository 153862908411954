
import { ECCN_STATUS, ECCN_Type, REVIEW_STATUS } from "./item-detail-model";

export namespace Item {
    export class Contribtor {
        Alias: string;
        Role: string;
        Name: string;
    }

    export class AmazonDependency {
        product_name: string;
        version_no: string;
        product_interaction_description: string;
    }

    export class ThirdPartyDependency {
        third_party_name: string;
        version: string;
        url: string;
    }

    export class QuestionMeta {
        question: string;
        version: string;
        answer: string | string[];
    }

    export class Data {
        Id: string;
        ItemFullName: string;
        CodeName: string;
        CustomerDefinedVersion: string;
        Confidential: "Yes" | "No" | "";
        Pm: string;
        PmName: string;
        PmCostCenterId: string;
        BusinessLineAttorney: string;
        BusinessLineAttorneyName: string;
        OtherContributors: Contribtor[];
        ECCN: string;
        ECCNStatus: ECCN_STATUS;
        ECCNLocked: boolean;
        ReviewStatus: REVIEW_STATUS;
        hasECCN: boolean;
        ECCNType: ECCN_Type;
        Form: QuestionMeta[];
        AccessibilityQuestionnaireAnswers: QuestionMeta[];
        AccessibilityStatus: "NotReviewed" | "Reviewing" | "Reviewed";
        QA: [];
        Description: string;
        OnlineDocumentation: string;
        MilitaryUse: "Yes" | "No" | "";
        ResearchProject: "Yes" | "No" | "";
        ItemType: string;
        LastBaselinedBy: string;
        LastBaselinedDate: string;
        Files: File[];
        AdminFiles: File[];
        AmazonDependencies: AmazonDependency[];
        ThirdPartyDependencies: ThirdPartyDependency[];
        Deletable: true;
        ECCNCount: number;
        CCATs: [string, string, string, string, string, string, string, string, string, string];
        CFR740: string;
        ManualClassificationDetails: string;
        ItemClassificationDetails: string;
        SingaporeStrategicGoodsCodes: string;

        CCATS: string;
        CCATSDate: string;
        SubjectToSemiAnnualReporting: string;
        SemiAnnualReportDate: string;
        SubjectToAnnualReporting: string;
        ENCType: string;
        AnnualReportDate: string;

        ExemptFromEscalations: string;
        ExemptFromBaseline: string;
        ReasonForBaselineExemption: string;

        AdjustedPeakPerformance: {
            ProcessorType: string;
            ProcessorsNumber: string;
            FloatingPoint: string;
            Frequency: string;
        };
        AssignedCCATs: boolean;
        Deprecated: boolean;
        Version: number;
        ItemRefs: string[];
        OwnerTeam: string;
        CET: string[];
        DualUse: "Yes" | "No" | "";
        ITAR: string;
        TemporaryECCN: string;

        static getDefaultData(): Data {
            return {
                ItemFullName: "",
                CodeName: "",
                CustomerDefinedVersion: "",
                Confidential: "No",
                Pm: "",
                PmName: "",
                PmCostCenterId: "",
                BusinessLineAttorney: "",
                BusinessLineAttorneyName: "",
                OtherContributors: [],
                Id: "",
                ECCN: "",
                ECCNStatus: ECCN_STATUS.PENDING_QUESTIONNAIRE_COMPLETION,
                ECCNLocked: true,
                ReviewStatus: REVIEW_STATUS.NO_STATUS,
                hasECCN: false,
                ECCNType: ECCN_Type.NO_ECCN,
                Form: [{ question: '1', version: "1", answer: "" }],
                AccessibilityQuestionnaireAnswers: [{ question: 'Accessibility-1', version: "1", answer: '' }],
                AccessibilityStatus: "NotReviewed",
                QA: [],
                Description: "",
                OnlineDocumentation: "",
                MilitaryUse: "",
                ResearchProject: "",
                ItemType: "",
                LastBaselinedBy: "",
                LastBaselinedDate: "",
                Files: [],
                AdminFiles: [],
                AmazonDependencies: [],
                ThirdPartyDependencies: [],
                Deletable: true,
                ECCNCount: 0,

                CCATs: ["", "", "", "", "", "", "", "", "", ""],
                CFR740: "",
                ManualClassificationDetails: "",
                ItemClassificationDetails: "",
                SingaporeStrategicGoodsCodes: "",

                CCATS: "",
                CCATSDate: "",
                SubjectToSemiAnnualReporting: "",
                SemiAnnualReportDate: "",
                SubjectToAnnualReporting: "",
                ENCType: "",
                AnnualReportDate: "",

                ExemptFromEscalations: "",
                ExemptFromBaseline: "",
                ReasonForBaselineExemption: "",

                AdjustedPeakPerformance: {
                    ProcessorType: "",
                    ProcessorsNumber: "",
                    FloatingPoint: "",
                    Frequency: "",
                },
                AssignedCCATs: false,
                Deprecated: false,
                Version: 1,
                ItemRefs: [],
                OwnerTeam: "",
                CET: [],
                DualUse: "Yes",
                ITAR: "",
                TemporaryECCN: ""
            };
        }
    };
}

